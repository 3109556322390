import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <h1>
        Edit About component or pages/about.jsx to include your information.
      </h1>
    );
  }
}

export default About;
